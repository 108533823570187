import { Box, CircularProgress, Dialog } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import Section from "../components/blocks/Section/Section"
import Layout from "../components/layouts/Layout/layout"
import Seo from "../components/seo"
import componentFactory from "../utils/componentFactory"
import "./evaluation-template.scss"

import LoginPopUp from "../components/sections/PopUpsFormationHandler/LoginPopUp/LoginPopUp"
import {
  getParticipantEvaluation,
  getPersonne,
  isLoggedInAndConfirmed,
  updateParticipantEvaluation,
} from "../services/auth"
import callApi from "../utils/callApi"
import ButtonBlock from "../components/blocks/ButtonBlock/ButtonBlock"
const EvaluationTemplate = ({ pageContext }) => {
  const { control, handleSubmit } = useForm({
    mode: "onTouched",
  })

  const [openLoginPopUp, setOpenLoginPopUp] = useState(false)
  const [personneId, setPersonneId] = useState()
  const [isResult, setIsResult] = useState(false)
  const [isSubmited, setIsSubmited] = useState(false)
  const [isUserConnected, setIsUserConnected] = useState(false)

  const personne = getPersonne()
  const handleParticipateFormation = async () => {
    try {
      const loggedInAndConfirmed = await isLoggedInAndConfirmed()

      if (loggedInAndConfirmed) {
        setPersonneId(personne.id)
        setOpenLoginPopUp(false)
      } else {
        setOpenLoginPopUp(true)
      }
    } catch (e) {
      if (e.response?.Data?.message) {
        console.log(e.response.Data.message)
      } else {
        console.log("Erreur inconnue. Merci de réessayer plus tard.")
      }
    }
  }
  useEffect(() => {
    handleParticipateFormation()
  }, [setPersonneId, setIsUserConnected, setOpenLoginPopUp])

  const fields = pageContext.item.fields
  const page = pageContext.page.attributes
  const evaluations = pageContext.item.evaluation

  const components = fields.map(i => componentFactory(i, pageContext, control))
  const [formData, setFormData] = useState({})

  const [evId, setEvId] = useState(null)
  const [evaluation, setEvaluation] = useState({})

  const [formation, setFormation] = useState([])
  const [loadingParticipant, setLoadingParticipant] = useState(true)
  const [loadingEvenement, setLoadingEvenement] = useState(true)
  const [currentParticipantId, setCurrentParticipant] = useState([])

  const fetchData = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const evIdParam = urlParams.get("ev_id")
    const userIdParam = urlParams.get("user_personne_id")
    if (personneId || isUserConnected) {
      try {
        if (personneId) {
          setLoadingParticipant(true)

          const data = await getParticipantEvaluation({
            evId: evIdParam,
            userPersonneId: userIdParam || personneId,
          })
          const participantId = data.data.participants.map(
            participant => participant.personne_id
          )
          setCurrentParticipant(participantId[0])
          setEvaluation(true)
          const extractedEvaluation = data.data.participants.map(
            participant => participant.evaluation
          )

          const initialFormData = extractedEvaluation[0] || {}

          const filteredFormData = Object.fromEntries(
            Object.entries(initialFormData).filter(
              ([key, question]) => !question.isInProfile
            )
          )
          const filteredFormDataFromProfile = Object.fromEntries(
            Object.entries(initialFormData).filter(
              ([key, question]) => question.isInProfile
            )
          )

          setFormData({
            ...filteredFormData,
            ...filteredFormDataFromProfile,
          })
        }
      } catch (err) {
        setEvaluation(false)
        console.log(err.message)
      } finally {
        setLoadingParticipant(false)
      }

      setEvId(evIdParam)

      try {
        const data = await callApi(
          "get",
          `/api/formationdetails/?id=${evIdParam}`
        )
        if (data.data.success) {
          setFormation(data.data.formation)
        }
      } catch (error) {
        setIsResult(true)
        console.log(error)
      } finally {
        setLoadingEvenement(false)
      }
    }
  }
  useEffect(() => {
    if (isUserConnected || personneId) {
      setPersonneId(personne.id)
      fetchData()
    }
  }, [isUserConnected, personneId])

  const onSubmit = async () => {
    console.log(formData)
    try {
      const formDataJson = JSON.stringify({ formData })
      const res = await updateParticipantEvaluation({
        formData: formDataJson,
        personneId,
        evId,
      })
      setIsSubmited(true)
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }
  if (formation && formation.id) {
    var ville = formation.etablissement.ville || ""
    var dep = formation.etablissement.code
      ? " (" + formation.etablissement.code + ")"
      : ""
    var localisation = !formation.typeFormation?.data?.attributes.isRemote
      ? ville + dep
      : formation.typeFormation?.data?.attributes.shortText
    var date_debut = new Date(formation.date_debut)
    var formatedDateDebut = date_debut.toLocaleDateString("fr-FR", {
      weekday: "long",
      //year: "numeric",
      month: "long",
      day: "numeric",
    })
    var date_fin = new Date(formation.date_fin)
    var formatedDateFin = date_fin.toLocaleDateString("fr-FR", {
      weekday: "long",
      //year: "numeric",
      month: "long",
      day: "numeric",
    })
    var dateFinInscription = new Date(date_debut)
    dateFinInscription.setDate(
      date_debut.getDate() // - formation?.formation_type_can_register_until
    )
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    var isPast = now.getTime() >= dateFinInscription
  }

  const formationTitle =
    formation.evenement_type?.titre === "Autre"
      ? formation?.theme_autre_titre
      : formation.evenement_type?.titre

  const rawDescription =
    evaluations.attributes && evaluations.attributes.description
      ? evaluations.attributes.description
          .replace("%START_DATE%", formatedDateDebut)
          .replace("%FORMATION_TITLE%", formationTitle)
      : ""

  const isAdminOrFormateur =
    ["Administrateur", "Animateur"].includes(personne && personne.profil) &&
    personne.id !== currentParticipantId
      ? true
      : false
  return (
    <>
      <Seo
        description={page.metaDescription}
        title={page.titre}
        lang="fr"
      ></Seo>
      <Dialog open={openLoginPopUp}>
        {openLoginPopUp && (
          <LoginPopUp
            openLoginPopUp={openLoginPopUp}
            setOpenLoginPopUp={setOpenLoginPopUp}
            setIsUserConnected={setIsUserConnected}
          />
        )}
      </Dialog>
      <Layout chemin={page.chemin}>
        {loadingParticipant || loadingEvenement ? (
          <Section className={"evaluation"}>
            <CircularProgress />
          </Section>
        ) : isResult ? (
          <Section className={"evaluation"}>
            <h2>aucun évenement trouvé...</h2>
          </Section>
        ) : evaluation && !loadingParticipant ? (
          <Section className={"evaluation"}>
            <Box
             sx={{  minWidth: 120, width: { lg: "50%", xs: "100%" } }}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              textAlign={"center"}
            >
              <h2>{evaluations.attributes.title}</h2>
              <h2 className="titre">{formationTitle}</h2>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {rawDescription}
              </ReactMarkdown>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              {isSubmited ? (
                <Section>
                  <h2>Votre reponse a été envoyée.</h2>
                </Section>
              ) : (
                <>
                  {components.map((component, index) =>
                    React.cloneElement(component, {
                      key: index,
                      formData,
                      control,
                      setFormData,
                      currentParticipantId,
                    })
                  )}
                  {!isAdminOrFormateur && (
                    <ButtonBlock text={"Envoyer"} type="submit"></ButtonBlock>
                  )}
                </>
              )}
            </form>
          </Section>
        ) : (
          <Section className={"evaluation"}>
            <h2>Vous n'êtes pas inscrit à cet événement.</h2>
          </Section>
        )}
      </Layout>
    </>
  )
}

export default EvaluationTemplate
